import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { LoginModal } from "../pages/Ecommerce/newCheckout/components/loginModal";
import Axios from "axios";
import { Spinner } from "reactstrap";

import Foguete from './../assets/images/cadorefoguete.png';
import { LoginPage } from "../pages/Ecommerce/newCheckout/components/pageModal";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [loginOpen, setLoginOpen] = useState(true);

    useEffect(() => {
        if (isAuthProtected) {
            Axios.post(process.env.REACT_APP_API + '/api/auth/validate-token', {
                type: localStorage.getItem('access'),
                user_id: localStorage.getItem('id'),
            }, { headers: { "Authorization": "Bearer " + localStorage.getItem('token') } })
                .then(response => {
                    setIsAuthenticated(true);
                })
                .catch(error => {
                    setIsAuthenticated(false);
                });
        } else {
            setIsAuthenticated(true);
        }
    }, [isAuthProtected]);

	if (isAuthenticated === null) {
        return <div className="dahsboard-loading"><img src={Foguete}/></div>;
    }

    if (!isAuthenticated) {
        return (
            <LoginPage open={loginOpen} onClose={() => setLoginOpen(false)} success={() => console.log('')} callbackUrl={window.location.pathname} />
        );
    }

    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};
	
// (
// 		<Route
// 			{...rest}
// 			render={props => {

// 				if (isAuthProtected && !localStorage.getItem("authUser")) {
// 					if(window.location.pathname !== "/login" && window.location.pathname !== "/logout"){
// 						localStorage.setItem("history_url", window.location.pathname)
// 					}
// 					setTimeout(() => {
// 						return (
// 							<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
// 						);						
// 					}, 300);
// 				}

// 				return (

// 					<Layout>
// 						{this.state.loginOpen ?
//                     		<LoginModal open={this.state.loginOpen} onClose={() => this.setState({loginOpen:false})} success={this.handleSuccess} callbackUrl={window.location.pathname}/>
//                 		:
// 							<Component {...props} />
// 						}
// 					</Layout>
// 				);
// 			}}
// 		/>
// 	);

export default AppRoute;

