import { AuthComponent } from '../../../../Authentication/Login/components/authComponent';
import './styles.scss';

export const LoginPage = ({open, onClose, success, checkout = null, callbackUrl = null}) => {

  const handleLogin = () => {
    onClose()
    success()
  }


  return(
    <>
      <div className={`login-page ${open ? 'active' : ''}`}>
        <div className="title">
          <h3>Faça login</h3>
        </div>
        <AuthComponent newCallback={handleLogin} checkout={checkout} callbackCustomUrl={callbackUrl}/>
      </div>
      <div className={`login-page-overlay ${open ? 'active' : ''}`}></div>
    </>
  )
}